import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import config from './config';
import SongDetails from './SongDetails';
import VideoTypeEnums from './VideoTypeEnums';

const Songs = (props) => {

  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);

  const movieId = props.movieId;
  console.log("movieId in Songs :" + movieId);
  const url = `${config.apiUrl}/movies/${movieId}/songs`;

  const isMobile = props.isMobile;

  useEffect(() => {
    fetchSongs(url).then((result) => {
      setSongs(result);
    });
  }, [url]);

  const fetchSongs = async (url) => {

    console.log('Inside fetchSongs for url :' + url);

    let jsonData = [];

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      return jsonData;

    } catch (error) {
      jsonData = [];
      console.error('Error fetching data:', error);
    } finally{
        setLoading(false);
    }
  };


  return (

    <div style={{listStyleType:'none'}}>
          <p> Songs : </p>
          {songs.map((item) => (
            <li key={item.songId} style={{fontSize:'12px'}}>
              <div style={{display:'flex', flexWrap:'wrap', border:'1px solid #454545'}}>
    	        <VideoPlayer type={VideoTypeEnums.SONG} title={item.songName} videoId={item.songCode} isMobile={isMobile} />
                <SongDetails song={item} />
              </div>
            </li> 
          ))}
    </div>


  );
}

export default Songs;

