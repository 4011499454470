import React, { useEffect, useState } from 'react';
import ActorInSongImageComponent from './ActorInSongImageComponent';

const SongDetails = (props) => {

  console.log("Inside SongDetails");

  const onActorClick = () => {
    alert("Hello Actor Clicked");
  }

  return (
    <div>
      <h3> Song Name : {props.song.songName} </h3>
      <h3> Language : {props.song.songLanguage} </h3>
      <h3> Actor(s) : </h3>
      <div style={{display:'flex', flexWrap:'wrap'}}>
      {props.song.actors.map((item) => (

        <ActorInSongImageComponent key={item.actorId} data_item={item} isClicked={false} selectedActor={null} onClick={onActorClick} />

      ))}
      </div>
      <h3> Composer(s) : </h3>
      {props.song.composers.map((item) => (
        <li key={item.composerId} style={{fontSize:'10px'}}>
            {item.composerName}
        </li>
      ))}
      <h3> Singer(s) : </h3>
      {props.song.singers.map((item) => (
        <li key={item.singerId} style={{fontSize:'10px'}}>
            {item.singerName}
        </li>
      ))}
    </div>
  );
}

export default SongDetails;

