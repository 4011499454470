import React from 'react';

const TermsOfService = (props) => {
  return (
      <center>
      <h1>
         **{props.name}**
      </h1>
      <br/>
      <div style={{textAlign:'justify', paddingLeft:'200px', paddingRight:'200px', display:'flex', flexWrap:'wrap'}}>

      <p>
Welcome to dhoomdb.com These Terms of Service ("Terms") govern your access to and use of our free streaming platform, which offers movies, TV shows, and music content without requiring a subscription or login. By using our service, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our platform.
      </p>

      <p>
**1. Acceptance of Terms**
By accessing or using dhoomdb.coms services, you agree to comply with these Terms, as well as our Privacy Policy. If you do not agree, you may not use our platform.
      </p>

      <p>
**2. Service Description**
dhoomdb.com offers free, no-login streaming of movies, TV shows, and music content. You can access our platform through compatible devices, and we may periodically update the content available on our platform at our discretion.
      </p>

      <p>
**3. Usage and Restrictions**
You may use our service for personal, non-commercial purposes only. You agree not to:
      </p>

      <p>
	  <ul>
	  <li>
Copy, reproduce, distribute, or modify any content from our platform without explicit permission.
	  </li>
	  <li>
Use our service to upload, transmit, or distribute harmful, unlawful, or offensive content.
	  </li>
	  <li>
Engage in any activity that may disrupt or interfere with the proper functioning of our platform.
	  </li>
	  <li>
Attempt to gain unauthorized access to our platform, servers, or any related infrastructure.
	  </li>
	  </ul>
      </p>

      <p>
**4. Information Ownership**
All content information available on dhoomdb.com including but not limited to movies, TV shows, music, and related materials, is owned by dhoomdb.com or its licensors. You are granted access to the website for personal use only. You may not download, copy, distribute, or exploit any information for commercial purposes without prior permission.
      </p>

      <p>
**5. Advertisements**
As part of our free service, you may encounter advertisements during your use of the platform. These advertisements are provided by third-party ad networks, and dhoomdb.com is not responsible for the content or behavior of these third-party advertisers.
      </p>

      <p>
**6. Termination and Suspension**
We reserve the right to modify, suspend, or discontinue any part of our platform at any time, with or without notice. We may also suspend or block access to the service for users who violate these Terms. You may stop using the platform at any time without prior notice.
      </p>

      <p>
**7. Limitation of Liability**
dhoomdb.com makes no warranties regarding the availability, reliability, or accuracy of the content on our platform. To the fullest extent permitted by law, we are not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of or inability to use our service.
      </p>

      <p>
**8. Changes to Terms**
We may update or modify these Terms at any time, and any changes will be posted on this page. The updated Terms will be effective immediately upon posting. By continuing to use our platform after changes are made, you accept the revised Terms.
      </p>

      <p>
**9. Contact Information**
If you have any questions or concerns about these Terms, please contact us at https://www.dhoomdb.com/contact

By accessing or using our service, you acknowledge that you have read, understood, and agreed to these Terms of Service.
      </p>

**10. For Your Information**
      <p>
        Launched as of October 2024, this website is still in the process of improvement. Keep checking us as we will be updating our website frequently. Thank you for visiting us!  </p>

      </div>

<br/>
<br/>
<br/>
      </center>

  );
}

export default TermsOfService;

