import React from 'react';

const Contact = (props) => {
  return (
      <center>
      <h1>
         **{props.name}**
      </h1>
      <br/>
      <div style={{textAlign:'justify', paddingLeft:'200px', paddingRight:'200px', display:'block', flexWrap:'wrap'}}>

      <p>
If you have any questions, concerns, or requests regarding our website, please feel free to reach out to us at:
      </p>

      <p>
Email: contact.dhoomdb@gmail.com
      </p>

      <p>
We will respond to your inquiry as soon as possible.
      </p>


<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
      </div>
      </center>

  );
}

export default Contact;

