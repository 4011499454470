import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import { useLocation, Link } from 'react-router-dom'; // useLocation goes along with useNavigate defined in another component
import config from './config';
import { useMediaQuery } from 'react-responsive';
import { Image } from 'react-native';
import Songs from './Songs';
import VideoTypeEnums from './VideoTypeEnums';

const MovieDetails = (props) => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  console.log("Inside MovieDetails");
  console.log("Inside MovieDetails isMobile :" + isMobile);


  var m_display = 'flex';
  if ( isMobile ) {
    m_display = 'block';
  }

  const location = useLocation();
  const state = location.state || {};
  const movieId = state.movieId;

  console.log("movie Idlocalhost :" + movieId);
  const url = `${config.apiUrl}/movies/id/` + movieId;

  const [movieDetails, setMovieDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMovieDetails(url).then((result) => {
      setMovieDetails(result);
    });
  }, [url]);

  const fetchMovieDetails = async (url) => {
 
    console.log('Inside fetchData for url :' + url);

    let jsonData = [];

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      return jsonData;
   
    } catch (error) {
      jsonData = [];
      console.error('Error fetching data:', error);
    } finally{
        setLoading(false);
    }
  };


  return (
    <div>
    {movieDetails ? (<div style={{display:m_display, textAlign:'left'}}>
	<div style={{paddingLeft:'10px', border: '1px solid #454545', display:'block'}}>

	  <h3> Movie : {movieDetails.movieDetails.movieName} </h3>
	  <div style={{display:m_display, textAlign:'left'}}> 
            <div style={{paddingRight:'10px'}}>
            <Image
              source={movieDetails.movieDetails.moviePoster}
              alt={movieDetails.movieDetails.movieName}
              style={styles.defaultImage}
            />
            </div>
	    { 
              (movieDetails != null && movieDetails.trailerCode != null) && (<VideoPlayer type={VideoTypeEnums.TRAILER} title="Movie Trailer" videoId={movieDetails.trailerCode} isMobile={isMobile} />)
	    }
	  </div>

	  <h3> Movie Details : </h3>
          <div style={{listStyleType:'none', display:'flex', textAlign:'center'}}>
          <p> Genres : </p>
          {movieDetails.movieGenres.map((item) => (
            <p style={{paddingLeft:'20px', fontSize:'12px', paddingRight:'20px'}}>
              <div style={{
                borderRadius: '25px',
                border: '1px solid #454545',
                background: '#454545',
                padding: '5px', 
                width: '100px' }}>
                {item.movieGenreName}
              </div>
            </p>
          ))}
          </div>


          <div style={{display:'flex', textAlign:'left'}}>
          <div style={{display:'block', textAlign:'left'}}>
          <p> Cast : </p>
          {movieDetails.actors.map((item) => (
            <div>
              <Link style={{fontSize:'12px', color:'white'}} to={item.actorUrl}>{item.actorName} </Link>
            </div>
          ))}
          </div>

          <div style={{display:'block', textAlign:'left', paddingLeft:'50px'}}>
          <p> Directors : </p>
          {movieDetails.directors.map((item) => (
            <p>
              <Link style={{fontSize:'12px', color:'white'}} to={item.directorUrl}>{item.directorName}</Link>
            </p>
          ))}
          </div>
          </div>

          <h3> Watch Full Length Movie : </h3>
	   <div>
	   { 
             (movieDetails != null && movieDetails.movieCode != null) && (<VideoPlayer type={VideoTypeEnums.MOVIE} title="Movie Video" videoId={movieDetails.movieCode}  isMobile={isMobile}/>)
	   }
	   </div>


           <Songs movieId={movieId} isMobile={isMobile}/>

           <div>
             <h2> Coming Soon... we will be releasing Movie Songs as well. So Stay Tuned. </h2>
	   </div>

	</div>

	<div>

	</div> 
        </div>
        ) : (
          <p>Loading data...</p>
        )}
    </div>
  );
}
const styles = {
  defaultImage: {
    width: '240px',
    height: '360px',
    cursor: 'pointer',
    borderRadius: 8,
    border: '1px solid white',
  },
};

export default MovieDetails;

